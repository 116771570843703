export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'VW-CA',
  hostName: 'https://vwca-analytics-web-v4-staging.azurewebsites.net',
  environmentName: 'staging',
  appName: 'VW-CA Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '7a090a9a-9422-4a46-9a48-5fd566c482c3'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#248eb6', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://vwca-analytics-api-v4-staging.azurewebsites.net/api',
  authUri: 'https://vwca-analytics-api-v4-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://vwca-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/vw-ca/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: true,
  v5Reports: ['WebsiteOverview','DigitalAdvertisingDashboard']
};
